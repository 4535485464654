<!-- 菜单管理 -->
<template>
  <Container>
    <template #header>
      <el-button-group>
        <el-button type="primary">新增</el-button>
      </el-button-group>
    </template>
    <el-table
      :header-cell-style="{background:'rgb(245, 247, 250)'}"
      border
      style="width: 100%; margin-bottom: 20px;"
      default-expand-all
      row-key="id"
      :data="tableData"
      :tree-props="{ children:'children'}"
      @selection-change="onSelectChange"
    >
      <el-table-column type="selection" width="50" />
      <el-table-column prop="name" label="名称" width="250">
        <template #default="{row}">
          <i :class="row.icon" />
          {{ row.name }}
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" width="100" />
      <el-table-column prop="path" label="Url" />
      <el-table-column prop="code" label="code" />
      <el-table-column label="操作" width="200">
        <template #default="{$index, row}">
          <el-button type="primary" size="mini" @click="onEdit($index, row)">编辑</el-button>
          <el-button type="danger" size="mini" @click="onDelete($index, row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </Container>
</template>

<script>
export default {
    data() {
        return {
            tableData: []
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {}
}
</script>

<style lang='scss' scoped>

</style>
